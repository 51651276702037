import {
  MONITORING_KPIS_API_URL,
  monitoringQueryKeys,
} from './monitoring.constants';
import { SupportOverviewKpisType } from './monitoring.types';
import { useApiQuery } from '../../hooks/query';

export const useSupportOverviewKpis = (isLabIncluded = false) =>
  useApiQuery<SupportOverviewKpisType>(
    `${MONITORING_KPIS_API_URL}?include_lab_or_sandbox=${isLabIncluded}`,
    isLabIncluded
      ? monitoringQueryKeys.kpis.withLabs()
      : monitoringQueryKeys.kpis.withoutLabs(),
    {
      staleTime: 0,
    }
  );
