import { Anchor, Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import { AuditLogType } from '@portals/types';

import { DASHBOARD_PATHS } from '../../../routes/dashboard/dashboard-paths.constants';

interface ClaimDeviceProps {
  auditLog: AuditLogType;
}

export function ClaimDevice({ auditLog }: ClaimDeviceProps) {
  const auditLogData = keyBy('name', auditLog.params);

  const hasDeviceModelParam = Boolean(auditLogData.device_model);

  if (hasDeviceModelParam && auditLogData.device_model.id) {
    return (
      <Text size="sm">
        Claimed {auditLogData.device_model.model}:{' '}
        <Anchor
          size="sm"
          component={Link}
          to={DASHBOARD_PATHS.dynamicPaths.deviceModel(
            auditLogData.device_model.id
          )}
        >
          {auditLogData.device_model.display_name}
        </Anchor>
      </Text>
    );
  }

  return (
    <Text size="sm">
      Claimed {auditLogData.device.model}: {auditLogData.device.display_name}
    </Text>
  );
}
